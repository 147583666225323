
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'

export default defineComponent({
  name: 'TeamsModalDelete',
  components: {
    TmColoredUl,
    TmModal,
    TmButton,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    selectedTeam: {
      type: Object,
    },
  },
  setup(props) {
    let selectedTeams = props.selectedTeam ? props.selectedTeam.name : ''
    let totalUsers = props.selectedTeam ? props.selectedTeam.totalUsers : 0
    let teamSuffix
    let currentlyText = selectedTeams

    if (props.selected?.length) {
      props.selected.forEach((item: any, i, { length }) => {
        selectedTeams += `${item.name}${i === length - 1 ? '' : ', '}`
        currentlyText = selectedTeams
        totalUsers += item.totalUsers
      })
      teamSuffix = props.selected.length > 1 ? 's' : ''
      if (props.selected.length > 1) {
        currentlyText = 'selected'
      }
    }

    const deleteModalTitle = ref('')
    const deleteModalButton = ref('')

    if (props.selected?.length < 2) {
      deleteModalTitle.value = 'Delete team'
      deleteModalButton.value = 'Delete'
    } else {
      deleteModalTitle.value = `Delete ${props.selected.length} team${props.selected.length > 1 ? 's' : ''}`
      deleteModalButton.value = deleteModalTitle.value
    }

    return {
      selectedTeams,
      teamSuffix,
      totalUsers,
      deleteModalButton,
      deleteModalTitle,
      currentlyText,
    }
  },
})
